<template>
  <div class="space-y-4">
    <asom-card>
      <div class="py-5">
        <h3 class="text-lg leading-6 font-medium text-gray-900">
          Order Information
        </h3>
      </div>
      <asom-alert v-if="error" variant="error" :error-message="error" />
      <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
        <div class="grid sm:grid-cols-1 md:grid-cols-2">
          <asom-form-field label="Order Number">
            <p class="font-semibold">
              {{ get(orderData, "inventoryOrderNo", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Order Date">
            <p class="font-semibold">
              {{ formatDate(get(orderData, "orderDate", "-")) }}
            </p>
          </asom-form-field>
          <asom-form-field label="Elapsed Days">
            <p class="font-semibold">
              {{ get(orderData, "elapsedDays", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Expected Delivery Date">
            <p class="font-semibold">
              {{ formatDate(get(orderData, "expectedDeliveryDate", "-")) }}
            </p>
          </asom-form-field>
          <asom-form-field
            label="Delivery Date"
            v-if="get(orderData, 'deliveryDate', null) !== null"
          >
            <p class="font-semibold">
              {{ formatDate(get(orderData, "deliveryDate", "-")) }}
            </p>
          </asom-form-field>
          <asom-form-field label="Status">
            <p class="font-semibold">
              {{ get(orderData, "status", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field label="Remarks">
            <p class="font-semibold">
              {{ get(orderData, "remarks", "-") }}
            </p>
          </asom-form-field>
          <asom-form-field
            class="col-span-2"
            v-if="orderData.attachments.length > 0"
            label="Supporting Document"
          >
            <asom-upload-file-list
              :files="orderData.attachments"
              disabled-remove
            />
          </asom-form-field>
          <asom-form-field label="Items" class="col-span-2">
            <asom-client-table
              v-if="!isLoading"
              :columns="[
                'inventoryTypeName',
                'inventoryGroup',
                'amount',
                'remarks',
              ]"
              :data="orderData.orderItems"
              :filterable="false"
            >
              <template v-slot:header_inventoryTypeName>Item Type</template>
              <template v-slot:header_amount>Quantity</template>
              <template v-slot:remarks="scopedSlots">
                <span>{{ scopedSlots.data ? scopedSlots.data : "NA" }}</span>
              </template>
            </asom-client-table>
            <div v-else class="text-center">
              <asom-icon icon="spinner" class="animate-spin" />
            </div>
          </asom-form-field>
        </div>
      </div>
      <div class="col-span-2 flex justify-end space-x-4 pt-8">
        <asom-button text="Back" variant="secondary" @click="$router.go(-1)" />
        <asom-button
          v-if="
            get(this.$route.params, 'statusEnum', null) ==
              orderStatus.PENDING_DELIVERY.VALUE
          "
          text="Move In"
          @click="moveIn"
        />
      </div>
    </asom-card>
  </div>
</template>

<script>
import get from "lodash.get";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { getInventoryOrderDetails } from "../../../services/inventory.service";
import { OrderStatus } from "../../../constants/APIEnums/inventoryEnums";

export default {
  name: "ViewOrder",
  data() {
    return {
      orderStatus: OrderStatus,
      error: null,
      isLoading: false,
      orderData: {
        inventoryOrderNo: get(this.$route.params, "inventoryOrderNo", "-"),
        orderDate: get(this.$route.params, "orderDate", "-"),
        deliveryDate: get(this.$route.params, "deliveryDate", "-"),
        expectedDeliveryDate: get(
          this.$route.params,
          "expecteDeliveryDate",
          "-"
        ),
        elapsedDays: get(this.$route.params, "elapsedDays", "-"),
        status: get(this.$route.params, "status", "-"),
        remarks: get(this.$route.params, "remarks", "-"),
        orderItems: get(this.$route.params, "orderItems", []),
        attachments: get(this.$route.params, "attachments", []),
      },
    };
  },
  mounted() {
    this.getOrderDetails();
  },
  methods: {
    get,
    displayUtcDate,
    formatDate(date) {
      if (date !== "-") return displayUtcDate(date);
      else return date;
    },
    moveIn() {
      this.$router.push({
        name: "Move In Items",
        params: this.$route.params,
      });
    },
    async getOrderDetails() {
      this.error = null;
      this.isLoading = true;
      const result = await getInventoryOrderDetails({
        inventoryOrderId: get(this.$route.params, "inventoryOrderId"),
      });
      if (result.success) {
        this.isLoading = false;
        this.setFormData(get(result.payload, "data"));
      } else {
        this.isLoading = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
    setFormData(data) {
      this.orderData.inventoryOrderNo = get(data, "inventoryOrderNo", "-");
      this.orderData.orderDate = get(data, "orderDate", "-");
      this.orderData.deliveryDate = get(data, "deliveryDate", "-");
      this.orderData.expectedDeliveryDate = get(
        data,
        "expectedDeliveryDate",
        "-"
      );
      this.orderData.elapsedDays = get(data, "elapsedDays", "-");
      this.orderData.status = get(data, "status", "-");
      this.orderData.remarks = get(data, "remarks", "-");
      this.orderData.orderItems = get(data, "orderItems", "-");
      let attachment = get(data, "inventoryAttachment", null);
      if (attachment !== null) this.orderData.attachments.push(attachment);
    },
  },
};
</script>
